import React, { Fragment, useRef, useState, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { down, saveButton, underlineButton } from "../Styling/Buttons"

const NotificationModal = ({
  open,
  setOpen,
  bankAccount,
  values,
  UpdateUser,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed lg:z-10 inset-0 overflow-y-auto p-18"
        onClose={() => {}}
      >
        <div className="flex lg:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center backdrop-blur sm:block sm:p-0 items-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray border-blue border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="text-start">
                  <div className=" flex justify-center">
                    <p>Du endrer nå kontonummeret ditt til:</p>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-base font-bold flex justify-center"
                  >
                    {`${bankAccount.slice(0, 4)} ${bankAccount.slice(
                      4,
                      6
                    )} ${bankAccount.slice(6)}`}
                  </Dialog.Title>
                </div>
                <div className="flex justify-center mb-4">
                  <p className=" text-center">
                    Pass på at kontonummeret er riktig, da det brukes til
                    utbetalinger ved reiseregninger, honorar, og annet!
                  </p>
                </div>
                <div className="flex items-center justify-evenly mt-8 ">
                  <button
                    className={
                      "border-2 border-blue p-1 px-3 pt-1 pb-2 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t " +
                      down
                    }
                    onClick={() => {
                      UpdateUser.mutate(values)
                      setOpen(false)
                    }}
                  >
                    Ok
                  </button>
                  <button
                    className={underlineButton}
                    onClick={() => setOpen(false)}
                  >
                    Rediger videre
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default NotificationModal
