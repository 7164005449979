import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, {
  Component,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { authToken } from "../Security/authToken"
import { Link, navigate, useStaticQuery } from "gatsby"
import {
  down,
  right,
  saveButton,
  showMoreButton,
  underlineButton,
} from "../Styling/Buttons"
import { GetIncomingSubscriptions, GetSubscriptions } from "./ReactQueries"
import { SubscriptionDonor } from "../MyPage/MySubscriptions"

export const DonorAgreementSubscription = ({
  props,
  setProps,
}: {
  props: SubscriptionDonor
  setProps: Dispatch<SetStateAction<SubscriptionDonor>>
}) => {
  if (props.paymentFrequency === "778380000") {
    setProps({ ...props, paymentFrequency: "månedlig" })
  } else if (props.paymentFrequency === "778380002") {
    setProps({ ...props, paymentFrequency: "halvåret" })
  } else if (props.paymentFrequency === "778380003") {
    setProps({ ...props, paymentFrequency: "året" })
  }

  return (
    <div className="pb-6">
      <div className="font-sans text-blue mb-2">
        <label>Giveravtale</label>
      </div>
      {/* If there is a donation agreement on the user */}
      {props.isDonorAgreement === true ? (
        <div>
          <div className="flex flex-col">
            <p className="font-sans mb-2">
              Du har <span className="font-sans font-bold">en fast </span>
              giveravtale på{" "}
              <span className="font-sans font-bold">
                {props.amount},-/{props.paymentFrequency}
              </span>
            </p>
          </div>
          <Link to="/administrer-giveravtale">
            <button className={saveButton + right}>Administrer avtalen</button>
          </Link>
          <div>
            <a
              href={"https://www.human.no/sporsmal-og-svar-om-giveravtaler"}
              target="_blank"
              className={underlineButton}
            >
              Spørsmål og svar om giveravtaler
            </a>
          </div>
        </div>
      ) : null}
      {/* If there is an incoming donation agreement on the user */}
      {props.isDonorAgreement === false && props.incoming === true ? (
        <div>
          <p className="font-sans">Giveravtalen blir nå aktivert</p>
        </div>
      ) : null}
      {/* If there is no donation agreement on the user */}
      {props.isDonorAgreement === false && props.incoming === false ? (
        <div>
          <div className="flex flex-col">
            <p className="font-sans mb-2">
              Du har <span className="font-sans font-bold">ingen </span>
              giveravtaler
            </p>
          </div>
          <Link to="/giveravtale">
            <button className={saveButton + right}>Start en giveravtale</button>
          </Link>
          <div>
            <a
              href={"https://www.human.no/sporsmal-og-svar-om-giveravtaler"}
              target="_blank"
              className={underlineButton}
            >
              Spørsmål og svar om giveravtaler
            </a>
          </div>
        </div>
      ) : null}
    </div>
  )
}
