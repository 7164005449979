import React, { Component, useCallback, useEffect, useState } from "react"
import { MyConsents, UpdateConsent } from "./MyConsents"
import MyMarketing from "./MyMarketing"
import { useMutation, useQueryClient } from "react-query"
import { deleteApi, patchApi } from "../Utils/WebApi-utils"
import { useMsal } from "@azure/msal-react"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useForm } from "react-hook-form"
import { GetProfile } from "../Utils/ReactQueries"
import {
  underlineButton,
  down,
  saveButton,
  showMoreButton,
} from "../Styling/Buttons"
import LoadingModal from "../Modal/LoadingModal"
import MyInterests from "./MyInterests"
import { StringParam, useQueryParam } from "use-query-params"

export type ConsentsAndInterests = {
  doNotEmail: boolean
  doNotBulkPostalMail: boolean
  doNotSms: boolean
  doNotPhone: boolean
  interests: string
}

export type WithoutInterests = Omit<ConsentsAndInterests, "interests">

const MyComAndMarketing = params => {
  const [consentsAndInterests, setConsentsAndInterests] = useState<
    ConsentsAndInterests | any
  >()

  const [addMemberList, setAddMemberList] = useState<any>([])
  const [removeMemberList, setRemoveMemberList] = useState<any>([])
  const [exisitingMarketingList, setExisitingMarketingList] = useState([])
  const { instance, accounts, inProgress } = useMsal()
  const [, updateState] = useState<any>()
  const queryClient = useQueryClient()
  const { register, handleSubmit, setValue, reset } = useForm()
  const [seeConsents, setSeeConsents] = useState(false)
  const [resetComAnConsents, setResetComAnConsents] = useState(false)
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [interestsArray, setInterestsArray] = useState([])
  const [interestReady, setInterestReady] = useState(false)
  const [tab, setTab] = useQueryParam("tab", StringParam)

  let message = ""
  let messageConsent = ""
  let messageInterest = ""

  const UpdateMyMarketingLists = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefSubscriptionLists/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success("Endringene på abonnementlister ble lagret", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["getMyMarketinglist"])
        queryClient.invalidateQueries(["getMarketinglist"])
      },
      onError: data => {
        toast.error("Noe gikk galt med abonnementlisten!")
      },
    }
  )

  useEffect(() => {
    if (
      userProfile.isSuccess &&
      userProfile.isRefetching === false &&
      userProfile?.data?.interests !== undefined
    ) {
      setInterestsArray(userProfile?.data?.interests?.split(","))
      // console.log(interestsArray)
      setInterestReady(true)
    } else if (
      userProfile.isSuccess &&
      userProfile.isRefetching === false &&
      userProfile?.data?.interests === undefined
    ) {
      setInterestsArray([])
      // console.log(interestsArray)
      // console.log("ingen")
      setInterestReady(true)
    }
  }, [userProfile.isSuccess, userProfile.isRefetching])

  useEffect(() => {
    if (resetComAnConsents) {
      setSeeConsents(true)
      setResetComAnConsents(false)
      setInterestsArray(userProfile?.data?.interests?.split(","))
    }
  }, [resetComAnConsents])

  const onReset = () => {
    setSeeConsents(false)
    setResetComAnConsents(true)
  }

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success(message.length > 0 ? message : messageConsent, {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Det har skjedd en feil, prøv igjen senere")
      },
    }
  )

  const DeleteInterests = useMutation(
    async values =>
      deleteApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts?field=pp_interests",
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success(message.length > 0 ? message : messageInterest, {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Det har skjedd en feil, prøv igjen senere")
      },
    }
  )

  useEffect(() => {
    if (interestsArray !== undefined && interestsArray.length > 0) {
      setConsentsAndInterests({
        ...consentsAndInterests,
        interests: interestsArray.join(","),
      })
      // console.log("Inne i useffect")
    } else if (interestsArray?.length === 0) {
      // console.log("Tomt array")
      setConsentsAndInterests({
        ...consentsAndInterests,
        interests: "null",
      })
    }
  }, [interestsArray?.length])

  let patchObj: any = {
    AddMemberList: [],
    RemoveMemberList: [],
  }
  const onSubmit = () => {
    patchObj = {
      AddMemberList: addMemberList,
      RemoveMemberList: removeMemberList,
    }

    const withInterest: ConsentsAndInterests | any = {
      doNotEmail: consentsAndInterests?.doNotEmail,
      doNotBulkPostalMail: consentsAndInterests?.doNotBulkPostalMail,
      doNotSms: consentsAndInterests?.doNotSms,
      doNotPhone: consentsAndInterests?.doNotPhone,
      interests: consentsAndInterests?.interests,
    }

    const withoutInterest: WithoutInterests | any = {
      doNotEmail: consentsAndInterests?.doNotEmail,
      doNotBulkPostalMail: consentsAndInterests?.doNotBulkPostalMail,
      doNotSms: consentsAndInterests?.doNotSms,
      doNotPhone: consentsAndInterests?.doNotPhone,
    }

    // If interests becomes 0 but had values before.
    if (
      interestsArray?.length === 0 &&
      (prevConsents.interests != null || prevConsents.interests != undefined)
    ) {
      //If interests becomes null and consents is also changed.
      if (
        withoutInterest.doNotBulkPostalMail != undefined ||
        withoutInterest.doNotSms != undefined ||
        withoutInterest.doNotPhone != undefined ||
        withoutInterest.doNotEmail != undefined
      ) {
        messageConsent = "Endringene i samtykke ble lagret"
        messageInterest = "Endringene i interesser ble lagret"
        UpdateUser.mutate(withoutInterest)
        DeleteInterests.mutate(consentsAndInterests)
        // console.log("1")
      }
      //If only interests changes from having values and becomes null
      else {
        message = "Endringene i interesser ble lagret"
        DeleteInterests.mutate(consentsAndInterests)
        // console.log("2")
      }
    }
    // If only interests changes but it doesnt become null.
    else if (
      prevConsents.interests != consentsAndInterests?.interests &&
      consentsAndInterests?.interests != undefined &&
      consentsAndInterests?.interests != "null" &&
      consentsAndInterests.doNotBulkPostalMail == undefined &&
      consentsAndInterests.doNotSms == undefined &&
      consentsAndInterests.doNotPhone == undefined &&
      consentsAndInterests.doNotEmail == undefined
    ) {
      message = "Endringene i interesser ble lagret"
      UpdateUser.mutate(consentsAndInterests)
      // console.log("3")
    }
    // If only consents changes and interests have a value.
    else if (
      prevConsents.interests == interestsArray?.join(",") &&
      (withoutInterest.doNotBulkPostalMail != undefined ||
        withoutInterest.doNotSms != undefined ||
        withoutInterest.doNotPhone != undefined ||
        withoutInterest.doNotEmail != undefined)
    ) {
      message = "Endringene i samtykke ble lagret"
      UpdateUser.mutate(withoutInterest)
      // console.log("4")
    }
    // If only consents changes and interests have no value.
    else if (
      interestsArray?.length == 0 &&
      (withoutInterest.doNotBulkPostalMail != undefined ||
        withoutInterest.doNotSms != undefined ||
        withoutInterest.doNotPhone != undefined ||
        withoutInterest.doNotEmail != undefined)
    ) {
      message = "Endringene i samtykke ble lagret"
      UpdateUser.mutate(withoutInterest)
      // console.log("5")
    }
    // If there are changes in both interests and consents.
    else if (
      consentsAndInterests?.interests != undefined &&
      (consentsAndInterests.doNotBulkPostalMail != undefined ||
        consentsAndInterests.doNotSms != undefined ||
        consentsAndInterests.doNotPhone != undefined ||
        consentsAndInterests.doNotEmail != undefined)
    ) {
      message = "Endringene i samtykke og interesser ble lagret"
      UpdateUser.mutate(consentsAndInterests)
      // console.log("6")
    }
    // else {
    //   message = "Endringene i samtykke og interesser ble lagret"
    //   UpdateUser.mutate(consentsAndInterests)
    //   console.log("6")
    // }
    if (
      patchObj.AddMemberList.length > 0 ||
      patchObj.RemoveMemberList.length > 0
    ) {
      UpdateMyMarketingLists.mutate(patchObj)
    }
    setAddMemberList([])
    setRemoveMemberList([])
    setConsentsAndInterests(null)
  }

  // console.log(consentsAndInterests)

  let prevConsents: ConsentsAndInterests = {
    doNotEmail: userProfile.data?.doNotEmail,
    doNotBulkPostalMail: userProfile.data?.doNotBulkPostalMail,
    doNotSms: userProfile.data?.doNotSms,
    doNotPhone: userProfile.data?.doNotPhone,
    interests: userProfile.data?.interests,
  }

  let prevConsentsWithoutInterests: WithoutInterests = {
    doNotEmail: userProfile.data?.doNotEmail,
    doNotBulkPostalMail: userProfile.data?.doNotBulkPostalMail,
    doNotSms: userProfile.data?.doNotSms,
    doNotPhone: userProfile.data?.doNotPhone,
  }

  // console.log(prevConsents?.interests)
  // console.log(consentsAndInterests?.interests)
  // console.log(interestsArray?.join(","))
  // console.log(prevConsents?.interests == interestsArray.join(","))
  // console.log(prevConsentsWithoutInterests)
  // console.log(interestsArray?.length == 0)

  useEffect(() => {
    if (tab === "communication") {
      setSeeConsents(true)
    }
  }, [tab])

  return (
    <div id="KommunikasjonOgNyhetsbrev">
      {UpdateUser.isLoading || UpdateMyMarketingLists.isLoading ? (
        <LoadingModal />
      ) : null}
      <button
        className={
          showMoreButton + "flex justify-between border-blue items-center py-3"
        }
        onClick={() => setSeeConsents(!seeConsents)}
      >
        {seeConsents ? (
          <h3 className="text-base font-sans font-semibold mb-0">
            Kommunikasjon og nyhetsbrev
          </h3>
        ) : (
          <h3 className="text-base font-sans font-normal mb-0">
            Kommunikasjon og nyhetsbrev
          </h3>
        )}
        {seeConsents ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>
      {seeConsents ? (
        <form name="contact" method="patch" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <UpdateConsent
              setConsents={setConsentsAndInterests}
              prevConsents={prevConsents}
              consents={consentsAndInterests}
            />
            <MyMarketing
              setAddMemberList={setAddMemberList}
              setRemoveMemberList={setRemoveMemberList}
              setExisitingMarketingList={setExisitingMarketingList}
              addMemberList={addMemberList}
              exisitingMarketingList={exisitingMarketingList}
              removeMemberList={removeMemberList}
            />
            <MyInterests
              interestsArray={interestsArray}
              setInterestsArray={setInterestsArray}
              interestReady={interestReady}
              consentsAndInterests={consentsAndInterests}
              setConsentsAndInterests={setConsentsAndInterests}
            />
            <button type="submit" className={saveButton + down}>
              Lagre endringer
            </button>
            <button type="reset" className={underlineButton} onClick={onReset}>
              Forkast endringer
            </button>
          </div>
        </form>
      ) : null}
      {seeConsents ? (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
      ) : (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
      )}
    </div>
  )
}
export default MyComAndMarketing
