import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  GetActivities,
  GetIncomingSubscriptions,
  GetMembership,
  GetProfile,
  GetSubscriptions,
} from "../Utils/ReactQueries"
import { saveButton, right } from "../Styling/Buttons"
import { navigate } from "gatsby"
import LoadingModal from "../Modal/LoadingModal"

const checkMembershipStatus = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeSsnExist, setSeeSsnExist] = useState(false)
  const [HefMembership, setHefMembership] = useState(false)
  const [IncomingMembership, setIncomingMembership] = useState(false)
  const [loading, setLoading] = useState(true)

  const userProfile = GetProfile(accounts, inProgress, instance)

  const MySubs = GetSubscriptions(accounts, inProgress, instance)

  const MyIncomingSubs = GetIncomingSubscriptions(
    accounts,
    inProgress,
    instance
  )

  const onBeMemberClickHef = () => {
    if (
      accounts[0]?.idTokenClaims["idp"] === "BankId" ||
      accounts[0]?.idTokenClaims["idp"] === "Vipps"
    ) {
      navigate("/hefinn/mine-opplysninger")
    } else {
      navigate("/verifisering")
    }
  }

  const checkActivities = GetActivities(
    accounts,
    inProgress,
    instance,
    seeSsnExist
  )

  const membership = GetMembership(accounts, inProgress, instance)

  // console.log(membership)

  // console.log(MyIncomingSubs)

  useEffect(() => {
    if (membership.isSuccess && membership?.data?.hefMember == true) {
      setHefMembership(true)
    }
  }, [membership.isSuccess, membership.isRefetching])

  useEffect(() => {
    if (MyIncomingSubs.isSuccess) {
      MyIncomingSubs?.data?.forEach(element => {
        if (element.productNumber == "HEF" || element.productNumber == "HUM") {
          setIncomingMembership(true)
        }
      })
    }
  }, [MyIncomingSubs.isSuccess, MyIncomingSubs.isRefetching])

  return (
    <div>
      {MyIncomingSubs.isLoading || membership.isLoading ? (
        <LoadingModal />
      ) : (
        <div>
          {MyIncomingSubs.isSuccess && HefMembership == true ? (
            <div className="flex flex-col">
              <p className="font-sans text-base mb-2">
                Medlemsnummeret ditt er{" "}
                <span className="font-bold">
                  {userProfile?.data?.customerNumber}
                </span>
              </p>
              {/* <p className="font-sans text-base mb-0">
              Vervekoden din er{" "}
              <span className="font-bold">
                {userProfile?.data?.recruitCode}
              </span>
            </p> */}
            </div>
          ) : MyIncomingSubs.isSuccess && HefMembership == false ? (
            <div>
              {checkActivities?.data?.expired.length > 0 ||
              checkActivities?.data?.upcoming.length > 0 ? (
                <div>
                  <div className="flex flex-col">
                    <p className="font-sans text-base mb-2">
                      Kundenummeret ditt er{" "}
                      <span className="font-bold">
                        {userProfile?.data?.customerNumber}
                      </span>
                    </p>
                  </div>
                  {IncomingMembership ? null : (
                    <button
                      onClick={onBeMemberClickHef}
                      className={saveButton + right + " mt-2 "}
                    >
                      Bli medlem i HEF
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  {IncomingMembership ? null : (
                    <div>
                      <div className="flex flex-col">
                        <p className="font-sans text-base mb-0">
                          Du er{" "}
                          <span className="font-sans font-bold text-base">
                            ikke{" "}
                          </span>
                          medlem
                        </p>
                      </div>
                      <button
                        onClick={onBeMemberClickHef}
                        className={saveButton + right + " mt-2 "}
                      >
                        Bli medlem i HEF
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default checkMembershipStatus
