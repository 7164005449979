import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { GetMembership, GetProfile } from "../Utils/ReactQueries"
import { authToken } from "../Security/authToken"
import { SearchableDropDown, SimpleDropDown } from "../Styling/DropwDowns"
import { Link, navigate, useStaticQuery } from "gatsby"
import { patchApi } from "../Utils/WebApi-utils"
import moment from "moment"
import {
  down,
  right,
  saveButton,
  showMoreButton,
  underlineButton,
} from "../Styling/Buttons"
import LoadingModal from "../Modal/LoadingModal"
import { BranchController } from "./BranchController"
import { CheckAge } from "../Common/Methods"
import { DonorAgreementSubscription } from "../Utils/DonorAgreementSubscription"
import { string } from "prop-types"
import { HefSubscription } from "../Utils/HefSubscription"
// import { HuSubscription } from "../Utils/HuSubscription"
import { StringParam, useQueryParam } from "use-query-params"

export type SubscriptionHef = {
  isHefMember: boolean
  // subsDonor: [SubscriptionDonor]
  memberSince: string
  name: string
  startDate: Date
  subscriptionNumber: string
  organization: string
  incoming: boolean
}

// export type SubscriptionHu = {
//   isHuMember: boolean
//   memberSince: string
//   name: string
//   startDate: Date
//   subscriptionNumber: string
//   organization: string
//   incoming: boolean
// }

export type SubscriptionDonor = {
  isDonorAgreement: boolean
  amount: number
  paymentFrequency: string
  incoming: boolean
}

// export type SubscriptionDonor = {
//   amount: number
//   frequency: string
// }

export const MySubscriptions = () => {
  const [seeSub, setSeeSub] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)
  const [tab, setTab] = useQueryParam("tab", StringParam)
  const [hefArray, setHefArray] = useState([])
  const [incomingHefArray, setIncomingHefArray] = useState("")
  const [huArray, setHuArray] = useState([])
  const [donorArray, setDonorArray] = useState<SubscriptionDonor>()
  const [propsHef, setPropsHef] = useState<SubscriptionHef>({
    isHefMember: false,
    memberSince: "",
    name: "",
    startDate: null,
    subscriptionNumber: "",
    organization: "",
    incoming: false,
  })
  // const [propsHu, setPropsHu] = useState<SubscriptionHu>({
  //   isHuMember: false,
  //   memberSince: "",
  //   name: "",
  //   startDate: null,
  //   subscriptionNumber: "",
  //   organization: "",
  //   incoming: false,
  // })
  const [propsDonor, setPropsDonor] = useState<SubscriptionDonor>({
    isDonorAgreement: false,
    amount: 0,
    paymentFrequency: "",
    incoming: false,
  })

  const userProfile = GetProfile(accounts, inProgress, instance)

  const GetSubscriptions = (account, inProgress, instance) =>
    useQuery(
      "getSubscription",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSubscription/MySubscriptions",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeSub,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const GetIncomingSubscriptions = (account, inProgress, instance) =>
    useQuery(
      "getIncomingSubscription",
      async () => {
        const token = await authToken(account, inProgress, instance)
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSalesOrder/CheckSubscription",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization: "Bearer " + token,
              },
            }
          )
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeSub,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const MySubs = GetSubscriptions(accounts, inProgress, instance)

  const MyIncomingSubs = GetIncomingSubscriptions(
    accounts,
    inProgress,
    instance
  )

  //Open and close sub component
  useEffect(() => {
    if (seeSub != false) {
      setSeeSub(true)
    }
  }, [seeSub])

  useEffect(() => {
    if (MySubs.isSuccess && MySubs.isRefetching === false) {
      setDonorArray(null)
      setPropsDonor({
        ...propsDonor,
        isDonorAgreement: false,
        amount: 0,
        paymentFrequency: "",
        incoming: false,
      })
      MySubs?.data?.forEach(element => {
        if (element?.productId?.name == "HEF Medlem") {
          setHefArray(element)
          setPropsHef({
            ...propsHef,
            isHefMember: true,
            name: element?.branchId?.name,
            startDate: element?.startDate,
            subscriptionNumber: element?.subscriptionNumber,
            organization: element?.organization,
          })
        }
        // else if (element.productId.name == "HU Medlem") {
        //   setHuArray(element)
        //   setPropsHu({
        //     ...propsHef,
        //     isHuMember: true,
        //     name: element?.branchId?.name,
        //     startDate: element?.startDate,
        //     subscriptionNumber: element?.subscriptionNumber,
        //     organization: element?.organization,
        //   })
        // }
        else if (element?.productId?.name == "Giveravtale") {
          setDonorArray(element)
          setPropsDonor({
            ...propsDonor,
            isDonorAgreement: true,
            amount: donorArray?.amount,
            paymentFrequency: donorArray?.paymentFrequency,
          })
        }
      })
    }
    if (MyIncomingSubs.isSuccess) {
      MyIncomingSubs?.data?.forEach(element => {
        // If incoming Hef membership
        if (element.productNumber == "HEF" && hefArray?.length == 0) {
          setPropsHef({ ...propsHef, incoming: true })
        }
        // If incoming Hu membership and no existing Hef membership on the user
        // else if (
        //   element.productNumber == "HUM" &&
        //   huArray.length == 0 &&
        //   hefArray.length == 0
        // ) {
        //   setPropsHu({ ...propsHu, incoming: true })
        //   setPropsHef({ ...propsHef, incoming: true })
        // }
        // If incoming Hu membership and there is an existing Hef membership on the user
        // else if (element.productNumber == "HUM" && huArray?.length == 0) {
        //   setPropsHu({ ...propsHu, incoming: true })
        // }
        // If incoming donor agreement
        else if (element.productNumber == "GIVER" && donorArray == undefined) {
          setPropsDonor({ ...propsDonor, incoming: true })
        }
      })
    }
  }, [MySubs.isSuccess, MySubs.isRefetching, donorArray?.amount])

  useEffect(() => {
    if (tab === "subscriptions") {
      setSeeSub(true)
    }
  }, [tab])

  console.log(MyIncomingSubs)
  console.log(MySubs)
  console.log(donorArray)
  console.log(propsDonor)

  return (
    <div>
      {MySubs.isLoading || MyIncomingSubs.isLoading ? (
        <LoadingModal />
      ) : (
        <div id="Medlemskap">
          <button
            className={
              showMoreButton +
              "flex justify-between border-blue items-center py-3"
            }
            onClick={() => {
              setSeeSub(!seeSub)
            }}
          >
            {seeSub ? (
              <h3 className="text-base font-sans font-semibold mb-0">
                Medlemskap og giveravtaler
              </h3>
            ) : (
              <h3 className="text-base font-sans font-normal mb-0">
                Medlemskap og giveravtaler
              </h3>
            )}
            {seeSub ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
          {seeSub ? (
            <div>
              {loadingError ? (
                <div>
                  <p className="font-sans">
                    Det har skjedd en feil med dine medlemskap, prøv igjen
                    senere
                  </p>
                </div>
              ) : (
                <div>
                  <HefSubscription props={propsHef} setProps={setPropsHef} />
                  {/* {userProfile?.data?.age >= 15 &&
                  userProfile?.data?.age < 26 ? (
                    <HuSubscription props={propsHu} setProps={setPropsHu} />
                  ) : null} */}
                  <DonorAgreementSubscription
                    props={propsDonor}
                    setProps={setPropsDonor}
                  />
                </div>
              )}
            </div>
          ) : null}
          {seeSub ? (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
          ) : (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
          )}
        </div>
      )}
    </div>
  )
}
