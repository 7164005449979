import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { authToken } from "../Security/authToken"
import { patchApi } from "../Utils/WebApi-utils"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useForm } from "react-hook-form"
import Checkbox from "../Styling/Checkbox"
import LoadingModal from "../Modal/LoadingModal"
import { GetProfile } from "../Utils/ReactQueries"

const MyInterests = ({
  interestsArray,
  setInterestsArray,
  interestReady,
  consentsAndInterests,
  setConsentsAndInterests,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const [loadingError, setLoadingError] = useState(false)
  const { register, handleSubmit, reset, setValue } = useForm()

  const [checkedInterested, setCheckedInterested] = useState(false)

  const userProfile = GetProfile(accounts, inProgress, instance)

  const GetOptionSet = (account, inProgress, instance) =>
    useQuery(
      "getOptionSet",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefOptionSet/OptionSet?optionSet=pp_interests",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            return false
          }
          return true
        },
      }
    )

  const optionSet = GetOptionSet(accounts, inProgress, instance)

  // console.log(optionSet)

  //const interestsList = userProfile?.data?.interests?.split(",")

  // console.log(userProfile?.data?.interests)
  //   console.log(interestsString)
  // console.log(interestsArray)

  const checkInterestsChecked = interestValue => {
    if (interestsArray === undefined || interestsArray === null) {
      //   setValue(listId, false)
      return false
    }
    const index = interestsArray.findIndex(
      item => item.toString() === interestValue.toString()
    )
    if (index === -1) {
      //   setValue(interestValue, false)
      return false
    } else {
      //   setValue(interestValue, true)
      return true
    }
  }

  const UpdateInterestArray = (interestValue, checkboxValue) => {
    const index = interestsArray?.findIndex(
      item => item.toString() === interestValue.toString()
    )
    if (checkboxValue && index === -1) {
      setInterestsArray([...interestsArray, interestValue.toString()])
    } else if (checkboxValue === false && index > -1) {
      setInterestsArray(
        interestsArray?.filter(item => item !== interestValue.toString())
      )
    }
  }

  return (
    <div>
      <div>
        <div className="space-y-4 my-8">
          <div className="flex justify-between items-center">
            <span className="font-sans text-base text-blue ">Interesser</span>
          </div>
          <div>
            {interestReady ? (
              <div>
                {optionSet?.data?.options?.map((item, i) => (
                  <div key={i} className="flex flex-row mt-4">
                    <Checkbox
                      key={item.value}
                      defaultChecked={checkInterestsChecked(item.value)}
                      onClick={value => UpdateInterestArray(item.value, value)}
                    />
                    <span className="ml-2 self-center font-sans text-xs">
                      {item?.lable?.userLocalizedLabel?.label}
                    </span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyInterests
