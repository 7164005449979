import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { authToken } from "../Security/authToken"
import moment from "moment"
import { Link, navigate } from "gatsby"
import { GetProfile } from "../Utils/ReactQueries"
import LoadingModal from "../Modal/LoadingModal"
import { linkNormal } from "../Styling/Links"
import { showMoreButton } from "../Styling/Buttons"

/**
 * This component shows all activities connected to the user, both ceremonies and courses.
 *
 * @version 1.0.1
 * @visibleName Activity
 * @author [Dylan Lesperance]
 * @author [Henrik Lie]
 */

const MyActivities = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeActiveActivities, setSeeActiveActivities] = useState(false)
  const [seeInactiveActivities, setSeeInactiveActivities] = useState(false)
  const [seeSsnExist, setSeeSsnExist] = useState(false)
  const userProfile = GetProfile(accounts, inProgress, instance)
  const ssn = userProfile.data?.socialSecurityNumber
  const [seeExpired, setSeeExpired] = useState(false)

  const GetActivities = (account, inProgress, instance) =>
    useQuery(
      "myActivities",
      async () => {
        await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefCeremonies/me", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeSsnExist,
        retry: 3,
      }
    )

  useEffect(() => {
    if (userProfile.data?.socialSecurityNumber != undefined) {
      setSeeSsnExist(true)
    }
  }, [userProfile])

  const MyActivities = GetActivities(accounts, inProgress, instance)
  const CeremonyType = new Map([
    [0, "Konfirmasjon"],
    [1, "Navnefest"],
    [2, "Begravelse"],
    [3, "Vigsel"],
  ])

  const test = "upcoming"
  console.log(MyActivities?.data?.[test])

  return (
    <div className="space-y-2 mt-12">
      <div>
        {MyActivities.status !== "error" ? (
          <div>
            <div className="mb-4">
              {MyActivities.isLoading ? (
                <div className="animate-bounce animate-pulse">
                  <span className="font-serif font-semibold text-lg text-blue pb-4 ">
                    Laster aktiviteter
                  </span>
                </div>
              ) : MyActivities?.data?.upcoming.length > 0 ? (
                <span className="font-serif font-semibold text-lg text-blue pb-4">
                  Kommende aktiviteter
                </span>
              ) : (
                <span className="font-serif font-semibold text-lg text-blue pb-4">
                  Du har ingen kommende aktiviteter
                </span>
              )}
            </div>
            <div className="space-y-2 border-t-2 border-blue flex flex-col pt-2">
              {/* Ceremonies */}
              {MyActivities?.data?.upcoming?.map((items, i) => (
                <div key={i} className="flex flex-col">
                  {items?.ceremonies !== undefined ? (
                    <Link
                      to={`/activities?${
                        "o=" +
                        items?.orderId +
                        "&p=" +
                        items?.personId +
                        "&type=ceremonies" +
                        "&status=upcoming"
                      }`}
                    >
                      <div
                        className={
                          linkNormal +
                          "space-x-2.5 items-start justify-start border-b-2 border-blue pb-2 transition-all grid grid-cols-2 gap-4 text-base"
                        }
                      >
                        <div className="col-start-1 col-span-1">
                          {items?.ceremonies?.dateAndTime !== undefined ? (
                            <span className="font-serif text-base">
                              {moment(items?.ceremonies?.dateAndTime).format(
                                "DD.MM"
                              )}
                            </span>
                          ) : (
                            <span className="font-serif text-base">
                              {items?.ceremonies?.dateAndTimeDisplay
                                .charAt(0)
                                .toUpperCase() +
                                items?.ceremonies?.dateAndTimeDisplay.slice(1)}
                            </span>
                          )}
                        </div>
                        <div className="col-start-2 col-span-1 flex flex-col text-sm">
                          <span className="font-sans font-bold text-base">
                            Seremoni
                          </span>
                          <span className="font-sans ">
                            {CeremonyType.get(items?.ceremonies?.type)} i{" "}
                            {items?.ceremonies?.location}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              ))}
              {/* Courses */}
              {MyActivities?.data?.upcoming.map((items, i) => (
                <div key={i} className="flex flex-col">
                  {items?.courses !== undefined ? (
                    <Link
                      to={`/activities?${
                        "o=" +
                        items?.orderId +
                        "&p=" +
                        items?.personId +
                        "&type=courses" +
                        "&status=upcoming"
                      }`}
                    >
                      <div
                        className={
                          linkNormal +
                          "space-x-2.5 items-start justify-start border-b-2 border-blue pb-2 transition-all grid grid-cols-2 gap-4"
                        }
                      >
                        <div className="col-start-1 col-span-1">
                          {items?.courses?.startDate !== undefined ? (
                            <span className="font-serif text-base">
                              {moment(items?.courses?.startDate).format(
                                "DD.MM"
                              )}
                              -{moment(items?.courses?.endDate).format("DD.MM")}
                            </span>
                          ) : (
                            <span className="font-serif text-base">
                              {items?.courses?.startDateDisplay
                                .charAt(0)
                                .toUpperCase() +
                                items?.courses?.startDateDisplay.slice(1)}
                            </span>
                          )}
                        </div>
                        <div className="col-start-2 col-span-1 flex flex-col text-sm">
                          <span className="font-sans font-bold text-base">
                            {items?.courses?.category}
                          </span>
                          <span className="font-sans ">
                            i {items?.courses?.location}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="mb-4 pb-4 border-b-2 border-blue">
            <span className="font-serif font-semibold text-lg text-blue pb-4">
              Du har ingen kommende aktiviteter
            </span>
          </div>
        )}
      </div>
      {MyActivities?.data?.expired.length > 0 ? (
        <div>
          <button
            type="button"
            className={showMoreButton}
            onClick={() => {
              setSeeExpired(!seeExpired)
            }}
          >
            {seeExpired ? (
              <h3 className="text-base font-sans font-semibold mb-0">
                Tidligere aktiviteter
              </h3>
            ) : (
              <h3 className="text-base font-sans font-normal mb-0">
                Tidligere aktiviteter
              </h3>
            )}
            {seeExpired ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
          {seeExpired ? (
            <div className="space-y-2 border-blue flex flex-col pt-2">
              <span className="font-serif font-semibold text-lg text-blue pb-4 border-b-2 pt-2">
                Tidligere aktiviteter
              </span>
              {/* Ceremonies */}
              {MyActivities?.data?.expired?.map((items, i) => (
                <div key={i} className="flex flex-col">
                  {items?.ceremonies !== undefined ? (
                    <Link
                      to={`/activities?${
                        "o=" +
                        items?.orderId +
                        "&p=" +
                        items?.personId +
                        "&type=ceremonies" +
                        "&status=expired"
                      }`}
                    >
                      <div
                        className={
                          linkNormal +
                          "space-x-2.5 items-start justify-start border-b-2 border-blue pb-2 transition-all grid grid-cols-2 gap-4 text-base"
                        }
                      >
                        <div className="col-start-1 col-span-1">
                          {items?.ceremonies?.dateAndTime !== undefined ? (
                            <span className="font-serif text-base">
                              {moment(items?.ceremonies?.dateAndTime).format(
                                "DD.MM"
                              )}
                            </span>
                          ) : (
                            <span className="font-serif text-base">
                              {items?.ceremonies?.dateAndTimeDisplay
                                .charAt(0)
                                .toUpperCase() +
                                items?.ceremonies?.dateAndTimeDisplay.slice(1)}
                            </span>
                          )}
                        </div>
                        <div className="col-start-2 col-span-1 flex flex-col text-sm">
                          <span className="font-sans font-bold text-base">
                            Seremoni
                          </span>
                          <span className="font-sans ">
                            {CeremonyType.get(items?.ceremonies?.type)} i{" "}
                            {items?.ceremonies?.location}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              ))}
              {/* Courses */}
              {MyActivities?.data?.expired.map((items, i) => (
                <div key={i} className="flex flex-col">
                  {items?.courses !== undefined ? (
                    <Link
                      to={`/activities?${
                        "o=" +
                        items?.orderId +
                        "&p=" +
                        items?.personId +
                        "&type=courses" +
                        "&status=expired"
                      }`}
                    >
                      <div
                        className={
                          linkNormal +
                          "space-x-2.5 items-start justify-start border-b-2 border-blue pb-2 transition-all grid grid-cols-2 gap-4"
                        }
                      >
                        <div className="col-start-1 col-span-1">
                          {items?.courses?.startDate !== undefined ? (
                            <span className="font-serif text-base">
                              {moment(items?.courses?.startDate).format(
                                "DD.MM"
                              )}
                              -{moment(items?.courses?.endDate).format("DD.MM")}
                            </span>
                          ) : (
                            <span className="font-serif text-base">
                              {items?.courses?.startDateDisplay
                                .charAt(0)
                                .toUpperCase() +
                                items?.courses?.startDateDisplay.slice(1)}
                            </span>
                          )}
                        </div>
                        <div className="col-start-2 col-span-1 flex flex-col text-sm">
                          <span className="font-sans font-bold text-base">
                            {items?.courses?.category}
                          </span>
                          <span className="font-sans ">
                            i {items?.courses?.location}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
          {seeExpired ? null : (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default MyActivities
