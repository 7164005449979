import React, {
  Component,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  down,
  right,
  saveButton,
  showMoreButton,
  underlineButton,
} from "../Styling/Buttons"
import { SubscriptionHef } from "../MyPage/MySubscriptions"
import { useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import moment from "moment"
import { BranchController } from "../MyPage/BranchController"

export const HefSubscription = ({
  props,
  setProps,
}: {
  props: SubscriptionHef
  setProps: Dispatch<SetStateAction<SubscriptionHef>>
}) => {
  const [seeBranch, setSeeBranch] = useState("")
  const [org, setOrg] = useState("")
  const { instance, accounts, inProgress } = useMsal()
  const [editMode, setEditMode] = useState(false)

  let branchMap = new Map([
    ["778380000", "hef"],
    ["778380001", "hu"],
  ])

  const editBranch = (orgValue, subNumber) => {
    console.log(orgValue)
    console.log(subNumber)
    setOrg(branchMap.get(orgValue))
    setSeeBranch(subNumber)
    setEditMode(!editMode)
  }

  const onBeMemberClickHef = () => {
    if (
      accounts[0]?.idTokenClaims["idp"] === "BankId" ||
      accounts[0]?.idTokenClaims["idp"] === "Vipps"
    ) {
      navigate("/hefinn")
    } else {
      navigate("/verifisering")
    }
  }

  return (
    <div className="pb-6">
      <div className="font-sans text-blue mb-2">
        <label>Medlemskap i Human-Etisk Forbund</label>
      </div>
      {/* If there is a membership for HEF on the user */}
      {props.isHefMember === true ? (
        <div className="mb-4">
          <div className="flex flex-col space-y-2">
            <label className="font-sans">
              Medlem siden{" "}
              <label className="font-bold">
                {moment(props?.startDate).format("DD.MM.YYYY")}
              </label>
            </label>
            {props?.name != undefined ? (
              <label className="font-sans">{props?.name}</label>
            ) : null}
          </div>
          {/* {seeBranch == props?.subscriptionNumber && editMode ? (
            <BranchController
              org={org}
              subNumber={props?.subscriptionNumber}
              setEditMode={setEditMode}
            />
          ) : null} */}
          {/* {seeBranch == props?.subscriptionNumber && editMode ? (
            <button
              onClick={() =>
                editBranch(props?.organization, props?.subscriptionNumber)
              }
              className={underlineButton + " mt-2 "}
            >
              Lukk
            </button>
          ) : (
            <button
              onClick={() =>
                editBranch(props?.organization, props?.subscriptionNumber)
              }
              className={underlineButton + " mt-2 "}
            >
              Bytt lokallag
            </button>
          )} */}
        </div>
      ) : null}
      {/* If there is an incoming membership for HEF on the user */}
      {props.isHefMember === false && props.incoming === true ? (
        <div>
          <p className=" font-sans ">
            Ditt medlemskap hos HEF blir nå aktivert
          </p>
        </div>
      ) : null}
      {/* If there is no membership for HEF on the user */}
      {props.isHefMember === false && props.incoming === false ? (
        <div>
          <div className="flex flex-col">
            <label className="font-sans">
              Du er <label className="font-sans font-bold">ikke </label>
              medlem
            </label>
          </div>
          <button
            onClick={onBeMemberClickHef}
            className={saveButton + right + " mt-2"}
          >
            Bli medlem i HEF
          </button>
        </div>
      ) : null}
    </div>
  )
}
