import React, { Component, useEffect, useRef } from "react"
import { useState } from "react"
import { fetchApi, patchApi, postApi } from "../Utils/WebApi-utils"
import { GetProfile } from "../Utils/ReactQueries"
import { useMsal } from "@azure/msal-react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import axios from "axios"
import { authToken } from "../Security/authToken"
import { Controller, useForm } from "react-hook-form"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { caseButton, right, showMoreButton } from "../Styling/Buttons"
import "../Styling/styles.css"
import "react-phone-input-2/lib/style.css"
import "react-quill/dist/quill.snow.css"
import { Link } from "gatsby"
import moment from "moment"
import LoadingModal from "../Modal/LoadingModal"
import { StringParam } from "serialize-query-params"
import { useQueryParam } from "use-query-params"

export const MyCases = () => {
  const [seeCases, setSeeCases] = useState(false)
  const [seeInactiveCases, setSeeInactiveCases] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const [loadingError, setLoadingError] = useState(false)
  const [tab, setTab] = useQueryParam("tab", StringParam)
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const [regardingObjectId, setRegardingObjectId] = useState("")
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()

  const GetActiveIncident = (account, inProgress, instance) =>
    useQuery(
      "getIncident",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefIncident/me/active", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeCases,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const MyActiveIncidents = GetActiveIncident(accounts, inProgress, instance)

  const GetInactiveIncident = (account, inProgress, instance) =>
    useQuery(
      "getInactiveIncident",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefIncident/me/inactive", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeInactiveCases,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const MyInactiveIncidents = GetInactiveIncident(
    accounts,
    inProgress,
    instance
  )

  useEffect(() => {
    if (tab === "case") {
      setSeeCases(true)
    }
  }, [tab])

  // console.log(MyInactiveIncidents)

  // console.log(regardingObjectId)

  return (
    <div>
      <div id="MineHenvendelser">
        {MyActiveIncidents.isLoading ||
        MyInactiveIncidents.isLoading ||
        loading ? (
          <LoadingModal />
        ) : (
          <div>
            <button
              className={
                showMoreButton +
                "flex justify-between border-blue items-center py-3"
              }
              onClick={() => setSeeCases(!seeCases)}
            >
              {seeCases ? (
                <h3 className="text-base font-sans font-semibold mb-0">
                  Mine henvendelser
                </h3>
              ) : (
                <h3 className="text-base font-sans font-normal mb-0">
                  Mine henvendelser
                </h3>
              )}
              {seeCases ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-xl font-bold font-serif"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-xl font-bold font-serif"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
            {/* Active cases */}
            {seeCases ? (
              <>
                {loadingError ? (
                  <p className="font-sans">
                    Det har skjedd en feil med dine henvendelser, prøv igjen
                    senere.
                  </p>
                ) : (
                  <div>
                    {MyActiveIncidents?.data?.length > 0 ? (
                      <div className="flex flex-col mt-4">
                        {MyActiveIncidents.data?.map((incident, i) => (
                          <div key={i} className="bg-white pt-2 pb-4 px-4 mb-8">
                            <div className="flex flex-col">
                              <label className="font-sans text-blue text-sm mt-2 mb-2">
                                {incident.caseTitle}
                              </label>
                              <p className="font-sans text-sm mb-0">
                                {moment(incident.createdOn).format(
                                  "DD.MM.YYYY"
                                )}
                              </p>
                            </div>
                            <Link
                              onClick={() => setLoading(true)}
                              to={`/henvendelser?${
                                "id=" + incident?.incidentId
                              }`}
                            >
                              <button className={caseButton + right + " mt-4 "}>
                                Vis samtale
                              </button>
                            </Link>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <p className="font-sans">Du har ingen henvendelser</p>
                      </div>
                    )}
                    {/* Inactive cases */}
                    <div>
                      <button
                        className="font-sans text-blue hover:text-orange-dark hover:cursor-pointer pb-4"
                        onClick={() => setSeeInactiveCases(!seeInactiveCases)}
                      >
                        {seeInactiveCases ? (
                          <div className="flex flex-row items-center justify-items-center">
                            <label className="border-b-2 font-bold hover:cursor-pointer">
                              Lukkede henvendelser
                            </label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 ml-1"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="flex flex-row items-center justify-items-center">
                            <label className="border-b-2 hover:cursor-pointer">
                              Lukkede henvendelser
                            </label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 ml-1"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        )}
                      </button>
                    </div>
                    {seeInactiveCases ? (
                      <>
                        {MyInactiveIncidents?.data?.length > 0 ? (
                          <div className="flex flex-col mt-4">
                            {MyInactiveIncidents.data?.map((incident, i) => (
                              <div
                                key={i}
                                className="bg-white pt-2 pb-4 px-4 mb-8"
                              >
                                <div className="flex flex-col">
                                  <label className="font-sans text-blue text-sm mt-2 mb-2">
                                    {incident.caseTitle}
                                  </label>
                                  <p className="font-sans text-sm mb-0">
                                    {moment(incident.createdOn).format(
                                      "DD.MM.YYYY"
                                    )}
                                  </p>
                                </div>
                                <Link
                                  onClick={() => setLoading(true)}
                                  to={`/lukkede-henvendelser?${
                                    "id=" + incident?.incidentId
                                  }`}
                                >
                                  <button
                                    className={caseButton + right + " mt-4 "}
                                  >
                                    Vis samtale
                                  </button>
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p className="font-sans">
                              Du har ingen lukkede henvendelser
                            </p>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                )}
              </>
            ) : null}
            {seeCases ? (
              <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
            ) : (
              <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
